import React from 'react';
import './Button.css';

const Button = ({ id, text, onClick, type = 'button', className, disabled }) => {
    return (
        <button id={id} className={`btn ${className}`} onClick={onClick} type={type} disabled={disabled}>
            {text}
        </button>
    );
};

export default Button;
