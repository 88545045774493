import React from 'react';
import './Navbar.css';
import logo from '../../assets/home/logo.png';
import Button from "../ui/button/Button";


const Navbar = () => {

    const scrollToForm = () => {
        const formElement = document.getElementById('wait-list-form');
        if (formElement) {
            formElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <nav className="navbar background-light">
            <img id={'nav-logo'} src={logo} alt="logo" height={44}/>
            <Button className="btn-primary btn-pill nav-btn" text="JOIN THE WAITLIST" onClick={scrollToForm}/>
        </nav>
    );
};

export default Navbar;