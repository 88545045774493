import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import logo from '../../assets/home/logo_cap.png';
import small_logo from '../../assets/home/logo.png';
import burger from '../../assets/home/foods/burger.png';
import cupcake from '../../assets/home/foods/cupcake.png';
import grape from '../../assets/home/foods/grape.png';
import folk from '../../assets/home/foods/folk.png';
import phone from '../../assets/home/phone_screen.png';
import Button from "../ui/button/Button";
import WaitListForm from "./WaitListForm";

const Home = () => {
    const [showLearnMore, setShowLearnMore] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setShowLearnMore(false);
            } else {
                setShowLearnMore(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToForm = () => {
        const formElement = document.getElementById('wait-list-form');
        if (formElement) {
            formElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <div>
            <Helmet>
                <title>Bhogi - Your Guide to the Next Restaurant Meal</title>
                <meta name="description"
                      content="Let Bhogi guide you to your next restaurant meal. Join our waitlist for a delightful dining experience tailored to your taste and wellness goals."/>
                <meta name="keywords"
                      content="Bhogi, food guide, restaurant recommendations, wellness, dining, food preferences"/>
                <meta name="author" content="Bhogi AI"/>

                <meta property="og:title" content="Bhogi - Your Guide to the Next Restaurant Meal"/>
                <meta property="og:description"
                      content="Join Bhogi to get personalized restaurant meal recommendations based on your taste and wellness goals."/>
                <meta property="og:image" content="/Icon-512.png"/>
                <meta property="og:url" content="https://bhogi.ai/"/>
                <meta property="og:type" content="website"/>

                <meta name="twitter:title" content="Bhogi - Your Guide to the Next Restaurant Meal"/>
                <meta name="twitter:description"
                      content="Join Bhogi to get personalized restaurant meal recommendations based on your taste and wellness goals."/>
                <meta name="twitter:image" content="/Icon-512.png"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            <div className="landing-section background-light section-1">
                <img id={'landing-logo'} src={logo} alt="Bhogi Logo"/>
                <h1 className="slogan font-primary">Let <span className="font-italic text-primary">Bhogi</span> guide
                    you to your next restaurant meal.</h1>
                <Button text="Join Waitlist" className="btn-primary" onClick={scrollToForm}/>
            </div>
            <div className={"landing-section background-dark section-2"}>
                <div className={"section-2-intro-container"}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <h1 className={"title font-primary text-primary"}><span
                            className={"font-bold"}>Sound Familiar</span>?
                        </h1>
                        <h4 className={"section-2-intro font-primary"}>You love food. You also care about
                            wellness.<br/>You are excited to go to a restaurant tonight and <br/>want to try something
                            new.
                            <br/>You scan restaurant sites<br/> read reviews from people you don’t know <br/>settle on a
                            restaurant <br/>sit down at the table and <br/>spend a lot of time studying the
                            menu<br/> you pick an entrée, despite feeling rushed...</h4>
                    </div>
                    <img src={phone} alt="Phone Screen" id="phone-screen"/>
                </div>
                <img src={burger} alt="Burger Logo" id="burger-1"/>
                <img src={folk} alt="Folk Logo" id="folk"/>
            </div>
            <div className={"landing-section background-light section-3 font-primary"}>
                <h1 className={"title text-primary"}>
                    We start by understanding the type of foodie you are.
                </h1>
                <h4 className={"intro"}>
                    <span className={"text-primary"}>Bhogi</span> gets to know you!
                    <li>Your taste preferences</li>
                    <li>Your mood on a given day</li>
                    <li>Your health goals</li>
                </h4>
                <img src={burger} alt="Burger Logo" id="burger-2"/>
                <img src={cupcake} alt="Cupcake" id="cupcake"/>
                <img src={grape} alt="Grape" id="grape"/>
            </div>
            <div className={"landing-section background-dark section-4 font-primary"}>
                <img src={small_logo} alt="Small Logo" id={'small_logo'} height={150}/>
                <h1 className={"title text-primary"}>Sounds wonderful..<br/>how can I get <img src={small_logo}
                                                                                               alt={"Small Logo"}/>?
                </h1>
                <h4 className={"intro"}>We are almost ready to beta launch! <br/>Please provide your contact info to
                    join our FREE trial phase.</h4>
                <WaitListForm id={"wait-list-form"}/>
                <h4 className={"intro"}>Don’t just hope for a delightful meal. Get on the Bhogi waitlist.</h4>
            </div>
            {showLearnMore && (
                <div className="learn-more">
                    Learn More
                    <div className={"icon-new-line"}>
                        <i className="fa-solid fa-angles-down"></i>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Home;