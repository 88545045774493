import React from 'react';
import './Footer.css';
import logo from '../../assets/home/logo_white.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <img id={'footer-logo'} src={logo} alt="logo" height={90}/>
                </div>
            </div>
            <div className="footer-bottom">
                <div className={"footer-link"}>
                    <a href="mailto:info@bhogi.ai"><i className="fa-regular fa-envelope"></i> info@bhogi.ai</a>
                    <a target={"_blank"} href="https://www.instagram.com/bhogi.ai/profilecard/?igsh=NTc4MTIwNjQ2YQ=="><i className="fa-brands fa-instagram"></i> Follow us</a>
                    <p>User information is for internal use only</p>
                </div>
                <p id={"copy-right"}>© Copyright 2024, Bhogi, Inc. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
