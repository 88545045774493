import React, { useState } from 'react';
import Button from "../ui/button/Button";
import avocado from "../../assets/home/foods/avocado.png";
import knife from '../../assets/home/foods/knife.png';

function WaitListForm({ id }) {
    const [formData, setFormData] = useState({
        Time: new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }),
        Name: '',
        Zip:'',
        Email: '',
        Phone: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const scriptURL = 'https://script.google.com/macros/s/AKfycbyoZMElI2VHgr8FWn-jrfd051mTJAPf9tIWq4a4QPF2dRKmKi2J5-Pc8E5xQ8adthNwBg/exec';

        try {
            await fetch(scriptURL, {
                method: 'POST',
                mode: 'no-cors',
                body: new URLSearchParams(formData)
            });
            setMessage('You have successfully joined the waitlist. See you soon!');
            setFormData({
                Time: new Date().toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                }), Name: '', Zip: '', Email: '', Phone: ''
            });
        } catch (error) {
            setMessage('Failed to submit. Please try again!');
        }
        setIsSubmitting(false);
    }

    return (
        <form onSubmit={handleSubmit} id={id}>
            <h2>Please provide your contact info</h2>
            <small>to join our FREE trial phase.</small>
            <div className={"form-group"}>
                <label>
                    Name
                </label>
                <input type="text" name="Name" value={formData.Name} onChange={handleChange} required
                       placeholder={"Jad.."}/>
            </div>
            <div className={"form-group"}>
                <label>
                    Zip Code
                </label>
                <input type="text" name="Zip" value={formData.Zip} onChange={handleChange} required
                       placeholder={"00000"}/>
            </div>
            <div className={"form-group"}>
                <label>
                    Email
                    <input type="email" name="Email" value={formData.Email} onChange={handleChange} required
                           placeholder={"example@bhogi.ai"}/>
                </label>
            </div>
            <div className={"form-group"}>
                <label>
                    Phone for SMS optional
                    <input type="text" name="Phone" value={formData.Phone} onChange={handleChange}
                           placeholder={"000-000-000"}/>
                </label>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Button id={"join-waitlist-btn"} className={"btn-secondary"} text="Submit" type="submit"
                        disabled={isSubmitting}/>
            </div>
            {message && <div className={"form-message"}>{message}</div>}
            <img src={avocado} alt="Avocado" id="avocado"/>
            <img src={knife} alt="Knife" id="knife"/>
        </form>
    );
}

export default WaitListForm;
