import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/home/Home";
import TryMe from "./components/tryMe/TryMe";
import Navbar from './components/nav/Navbar';
import Footer from './components/footer/Footer';


function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home/>}>
                    <Route index element={<Home/>}/>
                </Route>
                <Route path="/try-me" element={<TryMe/>}/>
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default App;
