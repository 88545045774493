import React from 'react'
import {Autocomplete, Button, Card, CardActions, CardContent, TextField, Typography} from "@mui/material";
import axios from "axios";
import styles from './styles.module.css'

export default class TryMe extends React.Component {
    state = {
        dishes: [], recommendation: []
    }

    componentDidMount() {
        axios.get('/api/dishes', {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
            },
        }).then(({data}) => {
            this.setState({dishes: data})
        })
    }

    fetchRecommendation(dishName) {
        if (!dishName) {
            this.setState({recommendation: []});
            return;
        }
        axios.get(`/api/recommendations/${dishName}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
            },
        }).then(({data}) => {
            this.setState({recommendation: data})
        }).catch(err=> console.log(err))
    }

    render() {
        console.log(this.state.dishes)
        return <div className={styles.page}>
            <Typography variant="h5" component="div">
                Pick a dish
            </Typography>
            <Autocomplete
                onChange={(data, newValue) => this.fetchRecommendation(newValue)}
                disablePortal
                id="combo-box-demo"
                options={this.state.dishes}
                getOptionLabel={(option) => option.toString()}
                sx={{width: 300}}
                renderInput={(params) => <TextField {...params} label="Dish"/>}
            />
            {this.state.recommendation.map(dish => {
                return <div className={styles.card}>
                    <Card sx={{width: 'calc(100% - 50px)', height: "200px"}}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                {dish}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined"
                                    onClick={() => window.open(`https://google.com/search?q=${dish}`, "_blank")}>Details</Button>
                        </CardActions>
                    </Card>
                </div>
            })}
        </div>;
    }
}